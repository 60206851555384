import React from "react"
import { Link } from "gatsby"
import menuStyles from "./menu.module.css"
export default props => {
  return (
    <ul className={menuStyles.menu}>
      {props.items
        ? props.items.map(item => (
            <li
              key={item.url}
              className={item.items ? menuStyles.dropdown : null}
            >
              <Link
                to={`/${item.url}/`}
                activeClassName={menuStyles.active}
                className={
                  props.location &&
                  props.location.toString().includes(item.name.toLowerCase())
                    ? menuStyles.active
                    : null
                }
                onClick={e => item.items && e.preventDefault()}
              >
                {item.name}
              </Link>
              {item.items ? (
                <div className={menuStyles.dropdownContent}>
                  {item.items.map(subItem => (
                    <Link
                      key={subItem.url}
                      to={`/${subItem.url}/`}
                      className={menuStyles.subItem}
                    >
                      {subItem.name}
                    </Link>
                  ))}
                </div>
              ) : null}
            </li>
          ))
        : null}
    </ul>
  )
}
