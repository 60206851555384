import React from "react"
import layoutStyles from "./layout.module.css"
import Menu from "../components/menu"
import Logo from "../components/logo"
import { Helmet } from "react-helmet"
export default props => {
  const [menu] = React.useState([
    { name: "O mnie", url: "o-mnie" },
    {
      name: "Oferta",
      url: "uslugi",
      items: [
        {
          name: "Psychoterapia dzieci i młodzieży",
          url: "oferta/psychoterapia-dzieci-i-mlodziezy",
        },
        {
          name: "Psychoterapia osób dorosłych",
          url: "oferta/psychoterapia-osob-doroslych",
        },
      ],
    },
    { name: "Kontakt", url: "kontakt" },
  ])
  return (
    <div className={layoutStyles.layout}>
      <Helmet>
        <html lang="pl" amp />
        <meta charSet="utf-8" />
        <title>Sylwia Osiecka - Psycholog, Psychoterapeuta - Bielsko-Biała</title>
        <meta
          name="description"
          content="Sylwia Osiecka, psycholog, psychoterapeuta  dzieci, młodzieży, osób dorosłych"
        />
      </Helmet>
      <div className={layoutStyles.logo}>
        <Logo className={layoutStyles.logo}>
          <strong>
            {"SyLWiA OSIecKA".split("").map((c, i) =>
              c !== " " ? (
                <span
                  key={`${c}${i}`}
                  className={layoutStyles[c.toLowerCase()]}
                >
                  {c}
                </span>
              ) : (
                " "
              )
            )}
          </strong>
          <span className={layoutStyles.sub}>
            psycholog, psychoterapeuta dzieci, młodzieży, osób dorosłych
          </span>
        </Logo>
      </div>
      <div className={layoutStyles.nav}>
        <Menu items={menu} location={props.location} />
      </div>
      <div className={layoutStyles.main}>{props.children}</div>
      <div className={layoutStyles.footer}>
        <span>_kbz_&copy;2019</span>
      </div>
    </div>
  )
}
