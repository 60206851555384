import React from "react"
import { Link } from "gatsby"
//import { Link } from "gatsby"
//@import url('https://fonts.googleapis.com/css?family=Barlow+Condensed:500');
export default props => {
  return (
    <h1 style={{ fontSize: "50px", margin: "0" }}>
      <Link to="/">{props.children}</Link>
    </h1>
  )
}
